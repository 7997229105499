





















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { SituacaoOrdemServicoService, TransportadoraService } from '@/core/services/residuo';
import { OrdemServico, OrdemServicoFoto, OrdemServicoServico } from '@/core/models/interna';
import { EnumSituacaoOrdemServico } from '@/core/models/residuo/Enumerados';
import { PageBase } from '@/core/models/shared';
import { ClienteService, MotoristaService, VeiculoService } from '@/core/services/geral';
import { OrdemServicoService } from '@/core/services/interna';
import { Motorista, Veiculo } from '@/core/models/geral';

@Component
export default class ListaOrdemServicoInterna extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  teste: any;
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Número', value: 'numero' },
    { text: 'Contrato', value: 'contrato.numero' },
    { text: 'Transportadora', value: 'transportadora.nome' },
    { text: 'Protocolo', value: 'protocolo'},
    { text: 'Data', value: 'data', type:'date'},
    { text: 'Base', value: 'base.nome' },
    { text: 'Porto', value: 'porto.nome' },
    { text: 'Embarcação', value: 'embarcacao.nome' },
    { text: 'Situação', value: 'situacao.nome' },
  ];

  item = new OrdemServico();
  service = new OrdemServicoService();
  abrirItem: boolean = false;

  clienteService = new ClienteService();
  clientes: any[] = [];
  isClienteLoading: boolean = false;
  onSearchCliente: any = null;

  transportadoraService = new TransportadoraService();
  transportadoras: any[] = [];
  isTransportadoraLoading: boolean = false;
  onSearchTransportadora: any = null;

  motoristaService = new MotoristaService();
  motoristas: Motorista[] = [];
  isMotoristaLoading: boolean = false;
  onSearchMotorista: any = null;

  veiculoService = new VeiculoService();
  veiculos: Veiculo[] = [];
  isVeiculoLoading: boolean = false;
  onSearchVeiculo: any = null;

  situacaoService = new SituacaoOrdemServicoService();
  situacoes: any[] = [];

  routeImpressao: string = "relatorioOrdemServicoInterna";
  imprimirDialog: boolean = false;

  dialogFoto: boolean = false;
  ordemServicoFotos: OrdemServicoFoto[] = [];

  sheet: boolean = false;
  filtro: any = {
    numero: null,
    clienteId: this.app.clienteId,
    transportadoraId: null,
    dataInicial: '',
    dataFinal: '',
    protocolo: '',
    situacaoId: null,
    veiculoId: null,
    motoristaId: null
  }

  itemOSClass(item: any){
    if(item.situacaoId == EnumSituacaoOrdemServico.Cancelado) 
      return 'itemOSClass'; 
  }

  @Watch('onSearchCliente')
  SearchCliente (val: string) {

    if (this.filtro.clienteId) return;
    if (this.isClienteLoading) return;
    if (!val) return;

    this.isClienteLoading = true
    this.clienteService.AutoComplete(val).then(
      res => {
        this.clientes = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    )
    .finally(() => {
      this.isClienteLoading = false
    });
  }  

  @Watch('onSearchTransportadora')
  SearchTransportadora (val: string) {

    if (this.filtro.transportadoraId) return;
    if (this.isTransportadoraLoading) return;
    if (!val) return;

    this.isTransportadoraLoading = true
    this.transportadoraService.AutoComplete(val).then(
      res => {
        this.transportadoras = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isTransportadoraLoading = false
    });
  } 

  @Watch('onSearchMotorista')
  SearchMotorista (val: string) {

    if (this.filtro.motoristaId) return;
    if (this.isMotoristaLoading) return;
    if (!val) return;

    this.isMotoristaLoading = true
    this.motoristaService.AutoComplete(val).then(
      res => {
        this.motoristas = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isMotoristaLoading = false;
    });
  }

  @Watch('onSearchVeiculo')
  SearchVeiculo (val: string) {

    if (this.filtro.veiculoId) return;
    if (this.isVeiculoLoading) return;
    if (!val) return;

    this.isVeiculoLoading = true
    this.veiculoService.AutoComplete(val).then(
      res => {
        this.veiculos = res.data;
      },
      err => this.$swal('Aviso', err.response.data, 'error')
    ).finally(() => {
      this.isVeiculoLoading = false;
    });
  }

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){
    
    if (isFiltro == true) {
      this.options.page = 1;
    }
    
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, 
      "Contrato.Cliente.Embarcacoes, Contrato.Cliente.Bases, Porto, Situacao, Transportadora ").then(
      res=>{
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err=>{
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }

  mounted(){

    this.situacaoService.ListarTudo()
      .then(
        res=>{
          this.situacoes = res.data.items;
          this.situacoes.unshift({
            id: null,
            nome: 'Todos'
          });
        },
        err=>{
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      )
  }

  AbrirDialogFotos(item: OrdemServico){

    this.service.ObterPorId(item.id,"Fotos").then(
      res=>{
        this.ordemServicoFotos = [];
        const ordemServico = res.data;        
        if(ordemServico.fotos.length > 0){
          this.ordemServicoFotos = ordemServico.fotos;
          this.dialogFoto = true;
        }
        else{
          this.$swal("Aviso", "Esta Ordem de Serviço não possui fotos", "warning");
        }        
      },
      err => {
        if (!err.response) {
          if(!navigator.onLine){
            this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
          }
          else{
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          }
        } 
        else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )

  }

  AbrirDialogCadastro(item: OrdemServico){
    
    if(item && !this.abrirItem){
      this.abrirItem = true;
      this.service.ObterPorId(item.id, 
        "Contrato.Cliente, Porto, Embarcacao, Base, Motorista, Veiculo.Tipo, Servicos.Servico, Situacao, Transportadora, Fotos").then(
        res=>{
          this.item = new OrdemServico(res.data);
          this.dialogCadastro = true;
        },
        err => {
          if (!err.response) {
            if(!navigator.onLine){
              this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
            }
            else{
              this.$swal("Aviso", "Não foi possível acessar a API", "error");
            }
          } 
          else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      ).finally(() =>{
        this.abrirItem = false;
      })
    }
    else{
      this.item = new OrdemServico();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  ConcluirManualmente(item: OrdemServico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja concluir a OS atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.ConcluirManualmente(item.id)
            .then(res => {
              if (res.status == 200){
                return res.data;
              }
            },
            err => {
              if (err.response.status == 403){
                this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
              }
              else{
                this.$swal('Aviso', err.response.data, 'error')
              }
            }
          )
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }

  Cancelar(item: OrdemServico){
    this.$swal({
      title: 'Digite a justificativa',
      input: 'text',
      confirmButtonText: 'Ok',
      showCancelButton: true,
    })
    .then((justificativa: any) => {

      if (justificativa.isDismissed)
        return;

      this.$swal({
        title: "Atenção!",
        text: "Tem certeza que deseja CANCELAR a OS atual?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {

          return this.service.Cancelar(item.id, justificativa.value).then(
            res => {
              if (res.status == 200) {
                return res.data;
              }
            },
            err => this.$swal("Aviso", err.response.data, "error")
          );

        },
        // @ts-ignore: Unreachable code error
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then(result => {
        if (result.value) {
          this.$swal("Aviso", result.value, "success");
          this.Atualizar();
        }
      });

    });
    
  }
  Excluir(item: OrdemServico){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
            .then(res => {
                if (res.status == 200){
                    return res.data;
                }
            },
            err => {
              if (err.response.status == 403){
                this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
              }
              else{
                this.$swal('Aviso', err.response.data, 'error')
              }
            }
          )
        },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }

  AbrirJanela(id: number, rota: string) {
    let routeLink = this.$router.resolve({ name: rota, params: { id: id.toString() } });
    window.open(routeLink.href, '_blank');
  }

  ExibeActions(item: OrdemServico) : boolean{
    return this.app.clienteId == null && item.situacaoId != EnumSituacaoOrdemServico.Faturado && item.situacaoId != EnumSituacaoOrdemServico.Cancelado && item.situacaoId != EnumSituacaoOrdemServico.ConcluidoManualmente;
  }

  ExibeActionsFaturado(item: OrdemServico) : boolean{
    return this.app.clienteId == null && item.situacaoId != EnumSituacaoOrdemServico.Cancelado && item.situacaoId != EnumSituacaoOrdemServico.ConcluidoManualmente;
  }

  Imprimir(){
    let routeLink = this.$router.resolve({name: this.routeImpressao,
      query:{
        numero: this.filtro.numero,
        clienteId: this.filtro.clienteId,
        transportadoraId: this.filtro.transportadoraId,
        dataInicial: this.filtro.dataInicial,
        dataFinal: this.filtro.dataFinal,
        protocolo: this.filtro.protocolo,
        situacaoId: this.filtro.situacaoId,
        veiculoId: this.filtro.veiculoId,
        motoristaId: this.filtro.motoristaId
      }
    });

    window.open(routeLink.href, '_blank');

    this.imprimirDialog = false;
  }
}

